<template>
  <div id="app" class="container mx-auto px-2 pb-12 pt-2">
    <div class="mt-2">
      <button class=" border border-blue-500 rounded-sm px-3 py-1 text-blue-500" type="button"
              @click.prevent="setLimit(15)"
              :class="{'bg-blue-500 text-white' : limit===15}"
      >
        Today
      </button>
      <button class=" ml-2 border border-blue-500 rounded-sm px-3 py-1 text-blue-500" type="button"
              @click.prevent="setLimit(null)"
              :class="{'bg-blue-500 text-white' : limit === null}"
      >
        All
      </button>
    </div>
    <div class="flex flex-col justify-center items-center mt-12">
      <div class="w-full flex flex-col justify-center items-center">

        <h1 class="font-bold text-2xl" v-if="selectedWord">{{ selectedWord.spanish }}</h1>
        <h2 class="font-bold text-xl" v-if="isCheck && selectedWord && selectedWord.english !== ''">
          {{ selectedWord.english }}</h2>
        <h2 class="font-bold text-xl" v-if="isCheck && selectedWord && selectedWord.french !== ''">
          {{ selectedWord.french }}</h2>
        <input ref="input" class="block mt-4 outline-none border-2 p-3 border-grey-500 bg-white text-black" type="text"
               v-model="inputValue"
               :disabled="isCheck"
               :class="{'border-red-500': isCheck && !valid, 'border-green-500': isCheck && valid}"/>
        <button class="mt-6 bg-blue-600 rounded-sm px-4 py-2 text-white" type="button" @click.prevent="next"
                v-if="isCheck">
          Next
        </button>
      </div>

    </div>

    <div class="mt-12 w-full md:w-2/3 lg:w-1/2 mx-auto">
      <button class="border border-blue-500 rounded-sm px-3 py-1 text-blue-500" type="button"
              @click.prevent="randomize"
      >
        Randomize
      </button>
      <button class="ml-2 border border-blue-500 rounded-sm px-3 py-1 text-blue-500" type="button"
              @click.prevent="viewList = !viewList"
      >
        Toggle list
      </button>
      <div v-if="viewList" class="py-12">
        <div class="flex flex-row justify-start items-center pb-3" v-for="word in words" :key="word.id">
          <p class="w-1/3 font-bold">{{ word.spanish }}</p>
          <p class="w-1/3">{{ word.english }}</p>
          <p class="w-1/3">{{ word.french }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  data() {
    return {
      inputValue: '',
      words: [],
      selectedIdx: 0,

      limit: 15,
      viewList: false,

      isCheck: false,
      valid: false
    }
  },
  created() {
    this.fetchWords();

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Enter') {
        this.validate()
      }
    })
  },
  mounted() {
    this.$refs.input.select();
    this.$refs.input.focus();
  },
  computed: {
    selectedWord() {
      return this.words[this.selectedIdx]
    }
  },
  methods: {
    randomize() {
      this.words = _.shuffle(this.words)
    },
    fetchWords() {
      let str = process.env.VUE_APP_API_URL + '/words'
      if (this.limit) {
        str += '/today?limit=' + this.limit
      }
      fetch(str)
          .then(res => res.json())
          .then(words => {
            this.words = _.shuffle(words)
          })
    },
    setLimit(limit) {
      this.limit = limit
      this.fetchWords();
    },
    validate() {
      if (this.isCheck) {
        this.next()
        return
      }
      let input = this.inputValue.toLowerCase()
      this.isCheck = true
      let valid = false
      if (this.selectedWord.english !== '') {
        valid = this.selectedWord.english.split(',').includes(input)
      }

      //dont check french if english is valid
      if (!valid && this.selectedWord.french !== '') {
        valid = this.selectedWord.french.split(',').includes(input)
      }
      this.valid = valid
    },
    next() {
      this.isCheck = false
      this.valid = false
      this.selectedIdx++
      this.inputValue = ''

      if ((this.selectedIdx + 1) > this.words.length) {
        this.selectedIdx = 0
      }


      setTimeout(() => {
        if (!this.$refs.input) return
        this.$refs.input.select();
        this.$refs.input.focus();
      }, 1)

    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
